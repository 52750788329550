import { createRouter, createWebHistory } from 'vue-router'
import Order from './components/Order.vue'
import AddressMutation from './components/AddressMutation.vue'

const routes = [
  {
    path: '/order',
    name: 'order',
    component: Order,
  },
  {
    path: '/address-mutation',
    name: 'AddressMutation',
    component: AddressMutation,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
