<template>
  <v-container>
    <v-form ref="form" @submit.prevent="sendForm">
      <v-select
        v-model="formData.community"
        :items="communities"
        item-value="id"
        item-title="name"
        dense
        label="Wählen Sie eine Gemeinde"
        required
      ></v-select>

      <v-text-field
        v-model="formData.parcel"
        label="Grundstücknummer"
        required
      ></v-text-field>

      <v-divider class="my-4"></v-divider>
      
      <h2>Angaben zur neuen Adresse</h2>

      <v-text-field
        v-model="formData.first_name"
        label="Vorname"
        required
      ></v-text-field>

      <v-text-field
        v-model="formData.last_name"
        label="Nachname"
        required
      ></v-text-field>

      <v-text-field
        v-model="formData.street"
        label="Strasse"
        required
      ></v-text-field>

      <v-text-field
        v-model="formData.house_number"
        label="Hausnummer"
        required
      ></v-text-field>

      <v-text-field
        v-model="formData.postal_code"
        label="PLZ"
        required
      ></v-text-field>

      <v-text-field
        v-model="formData.city"
        label="Ortschaft"
        required
      ></v-text-field>

      <v-textarea
        v-model="formData.remarks"
        label="Bemerkungen"
      ></v-textarea>

      <v-divider class="my-4"></v-divider>

      <h2>Kontaktangaben für Rückfragen</h2>

      <v-text-field
        v-model="formData.contact_info.name"
        label="Name"
        required
      ></v-text-field>

      <v-text-field
        v-model="formData.contact_info.first_name"
        label="Vorname"
        required
      ></v-text-field>

      <v-text-field
        v-model="formData.contact_info.email"
        label="Email"
        required
        type="email"
      ></v-text-field>

      <v-text-field
        v-model="formData.contact_info.phone"
        label="Telefon"
        required
        type="tel"
      ></v-text-field>

      <v-btn color="primary" type="submit">Senden</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "AddressMutation",
  data() {
    return {
      communities: [],
      formData: {
        community: '',
        parcel: '',
        first_name: '',
        last_name: '',
        street: '',
        house_number: '',
        postal_code: '',
        city: '',
        remarks: '',
        contact_info: {
          name: '',
          first_name: '',
          email: '',
          phone: ''
        }
      }
    };
  },
  mounted(){
    this.fetchCommunities();
  },
  methods: {
    async sendForm() {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/address-mutation`,
          this.formData,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (!response.ok) {
          throw new Error('Error sending form');
        }

        const result = await response.json();
        console.log(result);
        alert('Form sent successfully');
      } catch (error) {
        console.error('Error:', error);
        alert('Error sending form');
      }
    },
    fetchCommunities(){
      axios.get(`${process.env.VUE_APP_ZKL_BACKEND_URL}/v1/services/municipality`).then(result =>
      this.communities = result.data
    )
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
